import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/style.css"

const CovidPage = () => (
  <Layout>
    <SEO title="בטיחות לקוחותינו בזמן הקורונה" />
    <div className="row">
      <div className="col-12">
        <h2>בטיחות לקוחותינו בזמן הקורונה</h2>
        <p>
          למגיפת הקורונה הייתה השפעה חסרת תקדים על חיי כולנו. למרות זאת, אנו
          ממשיכים לספק את אותו שירות אמין תוך התחשבות בצורך להגן על בטיחות
          לקוחותינו. לפי הנחיות משרד הבריאות, הטכנאים שלנו עוטים מסכות ושומרים
          על המרחק החברתי המומלץ תוך מתן שירותינו. למען בטיחותם של כל המעורבים,
          אנו מבקשים מהלקוחות שלנו לעשות את אותו הדבר. ביחד, נוכל להבטיח שתקבלו
          את אותו שירות אמין בבטחה גם בזמנים אלו.
        </p>
      </div>
    </div>
  </Layout>
)

export default CovidPage
